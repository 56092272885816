import React, { useState, useEffect } from "react";
import Filter from "../../components/Filter";
import Articles from "../../components/Articles";
import useAxios from "../../Hook/useAxios";
import { orderList } from "../../utilities/Order";
import { useLocation } from "react-router-dom";
import { useUser } from '../../Hook/useUser';

function AllArticles({locate}) {
	const [selectedValues, setSelectedValues] = useState([]);
	const [allArticles, setAllArticles] = useState([]);
	const orderedArticles = orderList(allArticles, selectedValues);
	const data = useAxios("/articles?populate=*");
	const location = useLocation();
	const { user } = useUser();

	const handleFilterChange = (values) => {
		setSelectedValues(values);
	};

	useEffect(() => {
		// set page title
		const metaDescriptionTag = document.querySelector('meta[name="description"]');
		if (user === "parent") {
			document.title = 'Le blog des règles - Parlons Règles';
			if (metaDescriptionTag) {
				metaDescriptionTag.setAttribute('content', "Voici plein d'articles pour en apprendre plus sur les règles de vos enfants et comment aborder le sujet avec eux.");
			}
		} else {
			document.title = 'Ressources - Parlons Règles';
			if (metaDescriptionTag) {
				metaDescriptionTag.setAttribute('content', "Ces ressources vont vous permettre d'aborder le sujet des règles en classe et de construire des séquences pédagogiques sur les menstruations.");
			}
		}   

		// on setup SelectedValues si on a un params d'url pour activer le tag si on on arrive sur cette page via un tag button
		if (location.state) {
			setSelectedValues(location.state.tag);
		}
		const getDataArticles = () => {
			if (data) {
				const articles = data.data.data.map((item) => ({
					id: item.id,
					push_first: item.attributes.push_first,
					content: item.attributes.content,
					createdAt: item.attributes.createdAt,
					publishedAt: item.attributes.publishedAt,
					title: item.attributes.title,
					header: item.attributes.header_image.data.attributes.url,
					category: item.attributes.categories.data.map(
						(category) => ({
							name: category.attributes.name,
							visible: category.attributes.visible,
						})
					),
				}));
				// on renvoie uniquement les articles qui ont le tag Parents ou Relais selon la string locate (props)
				let filteredArticles = articles.filter(article => {
					return article.category.some(cat => {
						return cat.name === locate;
					});
				  });
				const sortedArticles = filteredArticles.sort(
					(a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
				);
				setAllArticles(sortedArticles);
			}
		};
		getDataArticles();
	}, [data]);

	const articlesFirst = allArticles.filter(
		(article) => article.push_first === true
	);

	return (
		<div className="allArticles">
			<div className="allArticles__articles">
				<div className="allArticles__une-container">
					<h1 className="allArticles__first">
						À la{" "}
						<b>
							<i>une</i>
						</b>
					</h1>
					<Articles ListArticles={articlesFirst} />
				</div>
	
				<div className="allArticles__filterContainer">
					<Filter onFilterChange={handleFilterChange} />
				</div>
				
				<h1 className="allArticles__last">
					Nos derniers{" "}
					<strong>
						<i>articles</i>
					</strong>
				</h1>
				<Articles ListArticles={orderedArticles} />
			</div>
		</div>
	);
}

export default AllArticles;