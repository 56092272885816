import React, { useState, useEffect } from 'react';
import useAxios from '../Hook/useAxios';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useUser } from '../Hook/useUser';

function Filter({ onFilterChange }) {
  const data = useAxios('/categories');
  const [allCategory, setAllCategory] = useState([]);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const location = useLocation();
  const { user } = useUser();

  useEffect(() => {
    if (location.state) {
      setSelectedButtons([location.state.tag])
    }
    const getDataVideo = () => {
      if (data) {
        let allCategory;
        if (user === "relai") {
          allCategory = data.data.data
            .filter(item => item.attributes.visible)
            .filter(item => !item.attributes.visible_young)
            .map(item => ({
              title: item.attributes.name,
            }));
        } else {
          allCategory = data.data.data
            .filter(item => item.attributes.visible)
            .filter(item => item.attributes.visible_young)
            .map(item => ({
              title: item.attributes.name,
            }));
        }
        setAllCategory(allCategory);
      }
    };
    getDataVideo();
  }, [data]);

  const handleButtonClick = (title) => {
    const currentButtons = Array.isArray(selectedButtons) ? selectedButtons : [title];
    let updatedButtons;
    if (currentButtons.includes(title)) {
      updatedButtons = currentButtons.filter(btn => btn !== title)
    } else {
      updatedButtons = [...selectedButtons, title];
    }
    onFilterChange(updatedButtons);
    setSelectedButtons(updatedButtons);
  };
  return (
    <div className="filter">
      <p className='filter__title'>Filtrer les sujets</p>
      {allCategory.map((category) => (
        <button
          key={category.title}
          onClick={() => handleButtonClick(category.title)}
          className={selectedButtons.includes(category.title) ? 'filter__button--actif focused' : 'filter__button--inactif focused'}
        >
          {category.title}
        </button>
      ))}
    </div>
  );
}

Filter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default Filter;
